/* eslint-disable no-case-declarations */
import {
    ADD_SIGNATURE_PROFILE,
    DELETE_SIGNATURE_PROFILE,
    FETCH_SIGNATURE_PROFILE,
    REFRESH_SIGNATURE_PROFILE,
    RESET_SIGNATURE_PROFILE,
    TOGGLE_DEFAULT_SIGNATURE_PROFILE,
} from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.signatureProfiles, action) {
    switch (action.type) {
        case ADD_SIGNATURE_PROFILE:
            return [...state, action.payload];

        case DELETE_SIGNATURE_PROFILE:
            if (action.payload.isDefault) {
                const changedState = changeDefaultProfileTo(state, 1);
                return changedState.filter((profile) => profile.id !== action.payload.id);
            }
            return state.filter((profile) => profile.id !== action.payload.id);

        case FETCH_SIGNATURE_PROFILE:
            const elements = action.payload.map((value) => value);
            return [...elements];

        case REFRESH_SIGNATURE_PROFILE:
            const index = state.findIndex((profile) => profile.id === action.payload.id);
            const refreshedState = state.map((value) => Object.assign({}, value));
            refreshedState[index].profileName = action.payload.profileName;
            return refreshedState;

        case TOGGLE_DEFAULT_SIGNATURE_PROFILE:
            return changeDefaultProfileTo(state, action.payload);

        case RESET_SIGNATURE_PROFILE:
            return [[]];

        default:
            return state;
    }
}

const changeDefaultProfileTo = (state, id) => {
    const prevIndex = state.findIndex((profile) => profile.isDefault);
    const targetIndex = state.findIndex((profile) => profile.id === id);
    let changedState = state.map((value) => Object.assign({}, value));
    changedState[prevIndex].isDefault = false;
    changedState[targetIndex].isDefault = true;
    return changedState;
};
